<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Edit Tbl Places</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                                <template v-if="pageReady">
                                    <div class="grid">
                                        <div class="col-12">
                                            <form ref="observer"  tag="form" @submit.prevent="submitForm()">
                                                <!--[form-content-start]-->
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Name *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <Textarea :class="getErrorClass('name')" class="w-full" ref="ctrlname" rows="5"  v-model="formData.name" placeholder="Enter Name"    type="textarea">
                                                                </Textarea>
                                                                <small v-if="isFieldValid('name')" class="p-error">{{ getFieldError('name') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Lat *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrllat" v-model.trim="formData.lat"  label="Lat" type="number" placeholder="Enter Lat"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('lat')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('lat')" class="p-error">{{ getFieldError('lat') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Lon *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrllon" v-model.trim="formData.lon"  label="Lon" type="number" placeholder="Enter Lon"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('lon')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('lon')" class="p-error">{{ getFieldError('lon') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Address *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <Textarea :class="getErrorClass('address')" class="w-full" ref="ctrladdress" rows="5"  v-model="formData.address" placeholder="Enter Address"    type="textarea">
                                                                </Textarea>
                                                                <small v-if="isFieldValid('address')" class="p-error">{{ getFieldError('address') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                City *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <Textarea :class="getErrorClass('city')" class="w-full" ref="ctrlcity" rows="5"  v-model="formData.city" placeholder="Enter City"    type="textarea">
                                                                </Textarea>
                                                                <small v-if="isFieldValid('city')" class="p-error">{{ getFieldError('city') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                State *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <Textarea :class="getErrorClass('state')" class="w-full" ref="ctrlstate" rows="5"  v-model="formData.state" placeholder="Enter State"    type="textarea">
                                                                </Textarea>
                                                                <small v-if="isFieldValid('state')" class="p-error">{{ getFieldError('state') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Retail *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlretail" v-model.trim="formData.retail"  label="Retail" type="number" placeholder="Enter Retail"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('retail')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('retail')" class="p-error">{{ getFieldError('retail') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Customer *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlcustomer" v-model.trim="formData.customer"  label="Customer" type="number" placeholder="Enter Customer"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('customer')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('customer')" class="p-error">{{ getFieldError('customer') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Saving *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlsaving" v-model.trim="formData.saving"  label="Saving" type="number" placeholder="Enter Saving"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('saving')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('saving')" class="p-error">{{ getFieldError('saving') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Orange *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlorange" v-model.trim="formData.orange"  label="Orange" type="text" placeholder="Enter Orange"      
                                                                class=" w-full" :class="getErrorClass('orange')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('orange')" class="p-error">{{ getFieldError('orange') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Green *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlgreen" v-model.trim="formData.green"  label="Green" type="number" placeholder="Enter Green"   step="any"    
                                                                class=" w-full" :class="getErrorClass('green')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('green')" class="p-error">{{ getFieldError('green') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Purple *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlpurple" v-model.trim="formData.purple"  label="Purple" type="number" placeholder="Enter Purple"   step="any"    
                                                                class=" w-full" :class="getErrorClass('purple')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('purple')" class="p-error">{{ getFieldError('purple') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--[form-content-end]-->
                                                <div v-if="showSubmitButton" class="text-center my-3">
                                                    <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="loading">
                                    <div class="p-3 text-center">
                                        <ProgressSpinner style="width:50px;height:50px" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, numeric, } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useEditPage } from '@/composables/editpage.js';
	const props = defineProps({
		id: [String, Number],
		pageName: {
			type: String,
			default: 'tbl_places',
		},
		routeName: {
			type: String,
			default: 'tbl_placesedit',
		},
		pagePath: {
			type : String,
			default : 'tbl_places/edit',
		},
		apiPath: {
			type: String,
			default: 'tbl_places/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		msgAfterUpdate: {
			type: String,
			default: "Record updated successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	const formDefaultValues = {
		name: "", 
		lat: "", 
		lon: "", 
		address: "", 
		city: "", 
		state: "", 
		retail: "", 
		customer: "", 
		saving: "", 
		orange: "", 
		green: "", 
		purple: "", 
	}
	const formData = reactive({ ...formDefaultValues });
	function onFormSubmited(response) {
		app.flashMsg(props.msgAfterUpdate);
		if(props.redirect) app.navigateTo(`/tbl_places`);
	}
	const rules = computed(() => {
		return {
			name: { required },
			lat: { required, numeric },
			lon: { required, numeric },
			address: { required },
			city: { required },
			state: { required },
			retail: { required, numeric },
			customer: { required, numeric },
			saving: { required, numeric },
			orange: { required },
			green: { required, numeric },
			purple: { required, numeric }
		}
	});
	const v$ = useVuelidate(rules, formData); //form validation
	const page = useEditPage(props, formData, v$, onFormSubmited);
	//page state
	const {
		submitted, // form api submitted state - Boolean
		saving, // form api submiting state - Boolean
		loading, // form data loading state - Boolean
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page computed propties
	const {
		apiUrl, // page current api path
		currentRecord, // current page record  - Object
	} = page.computedProps
	//page methods
	const { 
		load, // get editable form data from api
		submitForm, // submit form data to api
		getErrorClass, // return error class if field is invalid- args(fieldname)
		getFieldError, //  get validation error message - args(fieldname)
		isFieldValid, // check if field is validated - args(fieldname)
		 // map api datasource  to Select options label-value
	} = page.methods;
	onMounted(()=>{
		const pageTitle = "Edit Tbl Places";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
